<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="155" height="155">
        <g fill="transparent" stroke="rgb(48,154,0)">
            <circle cx="77" cy="77" r="72" stroke-width="4" stroke-dasharray="480" />
            <path
                d="M43,77 L63,97 L110,50"
                stroke-width="10"
                stroke-linecap="round"
                stroke-dasharray="95"
                stroke-dashoffset="95"
            />
        </g>
    </svg>
</template>

<style scoped lang="scss">
    @keyframes checkmark {
        to {
            stroke-dashoffset: 0;
        }
    }
    @keyframes checkmark-circle {
        from {
            stroke-dashoffset: 480px;
        }
        to {
            stroke-dashoffset: 960px;
        }
    }
    svg path {
        animation: checkmark 0.6s 0.6s linear forwards;
    }
    svg circle {
        animation: checkmark-circle 0.6s ease-in-out forwards;
    }
</style>
